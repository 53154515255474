<template>
  <div class="e-content">
    <div id="Introduction">
      <h1>Introduction</h1>
      <b-card title="About This Site">
        <b-card-text>Beat Someone Scan은 Kusama 네트워크를 기반으로 한 Blockchain Explorer입니다.</b-card-text>
        <b-card-text>Vue2, CLI, Vue Router 그리고 Vuex를 이용하여 제작되었습니다.</b-card-text>
        <b-card-text>Block, Extrinsic 그리고 Token 정보를 확인할 수 있습니다.</b-card-text>
        <b-card-text>비트썸원 스캔 사이트 바로가기:
          <b-link
              href="https://scan.beatsomeone.com/"
              target="_blank"
          >
            https://scan.beatsomeone.com/
          </b-link>
        </b-card-text>
      </b-card>



      <b-card id="Technical Specification" title="Technical Specification">
        <b-card-text>
          <ul class="e-list">
            <li>Vue2</li>
            <li>Vue CLI 4</li>
            <li>Vuex</li>
            <li>Vue Router</li>
            <li>Axios</li>
            <li>Clipboard</li>
            <li>Day.js</li>
            <li>Vue-timeago</li>
            <li>Vue-toasted</li>
            <li>Bootstrap-vue</li>
          </ul>
        </b-card-text>
      </b-card>

      <b-card>
        <b-img class="bsp-img" center src="@/assets/images/pages/bsoFullScreenShot.png"></b-img>
        <br/> <br/>
        <b-img class="bsp-img" center src="@/assets/images/pages/blocksMain.png"></b-img>
        <br/> <br/>
        <b-img class="bsp-img" center src="@/assets/images/pages/blocksDetails.png"></b-img>
        <br/> <br/>
        <b-img class="bsp-img" center src="@/assets/images/pages/transferMain.png"></b-img>
        <br/> <br/>
        <b-img class="bsp-img" center src="@/assets/images/pages/transferDetails.png"></b-img>
        <br/> <br/>
        <b-img class="bsp-img" center src="@/assets/images/pages/tokenMain.png"></b-img>
        <br/> <br/>
        <b-img class="bsp-img" center src="@/assets/images/pages/tokenDetails.png"></b-img>
      </b-card>
    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BLink, BImg} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['Introduction', 'TechnicalSpecification'],
      codeNpm:
          `
# For Yarn
yarn install

# For npm
npm install
  `,
      codeNpmRun:
          `
# For Yarn
yarn serve

# For npm
npm run serve
  `,
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)

  },
}
</script>

<style>
.bsp-img {
  width: 100%;
  min-width: 1100px
}
@media (max-width: 1780px) {
  .bsp-img {
    width: 100%;
    min-width: 800px
  }
}
  @media (max-width: 1476px) {
    .bsp-img {
      width: 100%;
      min-width: 600px
    }
}

@media (max-width: 1276px) {
  .bsp-img {
    width: 100%;
    min-width: 400px
  }
}

@media (max-width: 493px) {
  .bsp-img {
    width: 100%;
    min-width: 100px
  }
}
</style>